import React from 'react';
import { Link } from 'gatsby';

import styles from './Contact.module.scss';

interface IContactProps {}

interface IContactState {
  isEnabled: boolean;
  isSent: boolean;
  name: string;
  email: string;
  message: string;
}

class Contact extends React.PureComponent<IContactProps, IContactState> {
  constructor(args: any) {
    super(args);

    this.state = {
      isEnabled: false,
      isSent: false,
      name: '',
      email: '',
      message: '',
    };
  }

  onChangeName = (e: any) => {
    this.setState({ name: e.target.value }, () => {
      this.update();
    });
  };

  onChangeEmail = (e: any) => {
    this.setState({ email: e.target.value }, () => {
      this.update();
    });
  };

  onChangeMessage = (e: any) => {
    this.setState({ message: e.target.value }, () => {
      this.update();
    });
  };

  update = () => {
    const isEnabled =
      this.state.name.length > 0 && this.state.email.length > 0 && this.state.message.length > 0;

    this.setState({ isEnabled });
  };

  send = () => {
    this.setState({ isSent: true });
  };

  public render() {
    return (
      <div className={styles.contact}>
        {!this.state.isSent ? (
          <form>
            <div className="grid-x grid-padding-x">
              <div className="small-12">
                <label>
                  Name
                  <input
                    type="text"
                    placeholder="Please enter your name"
                    onChange={this.onChangeName}
                  />
                </label>
              </div>
              <div className="small-12">
                <label>
                  Email
                  <input
                    type="email"
                    id="middle-label"
                    placeholder="Please enter your email"
                    onChange={this.onChangeEmail}
                  />
                </label>
              </div>
              <div className="small-12">
                <label>
                  What is it, that we should know from you?
                  <textarea
                    placeholder="My message for you..."
                    rows={4}
                    onChange={this.onChangeMessage}
                  />
                </label>
              </div>
              <div className="small-12">
                <button
                  className="button expanded"
                  onClick={this.send}
                  disabled={!this.state.isEnabled}
                >
                  Send
                </button>
              </div>
              <div className="small-12">
                <Link className="button expanded hollow" to="/">
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        ) : (
          <div>
            <h3>Thank you!</h3>
            <p>We will answer your message as soon as possible.</p>
          </div>
        )}
      </div>
    );
  }
}

export default Contact;
