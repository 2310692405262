import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Contact from '../components/Contact';

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" keywords={[`gatsby`, `application`, `react`]} />
    <section className="grid-container">
      <div className="grid-x grid-margin-x">
        <div className="cell small-12">
          <h1>Contact</h1>
          <p>Let's get in touch.</p>
          <Contact />
        </div>
      </div>
    </section>
  </Layout>
);

export default ContactPage;
